<template>
  <v-container>
    <div class=" text-center mb-2">
    <div class="font-weight-bold text-center">{{event.name}} </div>
    <div class="md-font font-italic text-center"> {{$moment(event.event_date).format("MMM, DD YYYY")}} /  {{event.venue}}</div>
    <span :class="event.status=='Open'?'text-success':'text-warning'">Status: {{event.status}}</span>
    <v-btn color="info" text x-small class="ml-8" @click="guide=true"><v-icon small left>mdi-information</v-icon>Guidelines</v-btn>
    
  </div> 
                <template v-if="event.status!='Open'">
              <v-alert class="text-center" color="warning" dark>
                  Betting for this event is now closed!
              </v-alert>
             </template> 
        <v-col cols="12" class="pa-2 text-center font-italic def-font" v-if="event.status=='Upcoming'">
            We are finalizing the entry names for this event. <br/> STAY TUNED!
        </v-col> 
    <v-toolbar dense elevation="0">
      <v-spacer/>
              <!-- <span class="text-h6 font-weight-bold text-info" v-if="gamename">{{gamename}}</span>
              <span class="font-weight-bold text-error" v-else>Select your pick!</span>
                  <v-spacer/> -->
                  <v-btn v-for="(item, index) in event.picks" :key="index" 
                  elevation="1" tile @click="selected=[],gamename=item" 
                  :color="gamename==item?'primary':''">{{ item }}</v-btn>
                  <!-- <span style="width: 130px" >
                 
                     <v-select
                       dense
                       v-model="gamename"
                       outlined
                       label="SELECT"
                    @change="selected=[]"
                       hide-details
                       :items="event.picks"
                       return-object
                     />
                  </span> -->
                  <v-spacer/>   
            </v-toolbar>  
            <template v-if="!gamename">
                  <v-alert color="warning" dark dense class="text-center">
                      <v-icon>mdi-alert</v-icon> <span class="font-italic">PLEASE SELECT PICK!</span>
                  </v-alert>
                 </template>
            <v-divider />
            
            <v-row>
             
              <v-col cols="12">
                      <div class="pa-2 text-info text-body-2">
                        <span style="float: right" @click="entrypop = true"><v-icon>mdi-arrow-expand</v-icon></span>
                        <v-icon left color="warning">mdi-ticket-confirmation</v-icon><span class="font-weight-bold text-info">({{entries.length}} Entries) {{event.status=='Open'?'Tap to pick.':'Scoreboard'}}  </span>
                      </div>
                      
                      <div class="entrycon">             
                      <v-row no-gutters>
                     
                        <template v-if="event.status=='Open'">
                        <v-col cols="6" class="pa-1" v-for="(item,index) in entries" :key="index"><v-btn  elevation="1" small tile block @click="selectEntry(item)"> {{item.name}}</v-btn></v-col>
                       
                      </template>
                      <template v-else>
                            <table class="va-table  md-font">
                              <tr>
                                <!-- <th width="50">No.</th> -->
                                <th  class="text-left">Name</th>
                                <th  class="text-left" width="40%">---</th>
                                <th class="text-center">SCORE</th>
                              </tr>
                              <tr v-for="(item, index) in BYTOPSCORES" :key="index">
                                <!-- <td class="text-center" :class="$IsEven(index)?'td-even':'odd-td'">{{item.entry_no}}</td> -->
                                <td class="pa-1" :class="$IsEven(index)?'td-even':'odd-td'">{{item.name}}</td>
                                <td  :class="$IsEven(index)?'td-even':'odd-td'">
                                  <span v-for="(fight, f) in item.fights" :key="f+'-f'" class="pa-1">
                                  {{item.fights[f]}}</span>
                                  
                                </td>
                                <td class="text-center font-weight-bold" :class="$IsEven(index)?'td-even':'odd-td'">{{item.score}}</td>
                              </tr>
                          </table>
                       </template> 

                      
                      </v-row>
                    </div> 
                  </v-col>

              <v-col cols="12">
                <template v-if="selected.length>0">
                  <span class="font-italic md-font">Your pick(s)</span>
                  <v-divider class="mb-2"/>
                        <v-chip class="ma-1" outlined label small color="primary" dark v-for="(item,index) in selected" :key="index">{{item.name}} <v-icon right small  @click="removeEntry(index)">mdi-close-circle</v-icon></v-chip>
                      <v-divider class="ma-2"/>
                      </template>
                <template v-if="gamename">
                  <div class="text-body-2 pa-2"><v-icon left color="warning">mdi-group</v-icon><span class="font-weight-bold text-h6">{{gamename}}</span> - <span class="font-weight-bold text-info">{{event.status=='Open'?'Select amount bracket.':'Brackets'}}   </span></div>
                
                <v-row no-gutters>
                  
                  <v-col cols="12" sm="6" class="text-center" v-for="(bracket, index) in event.brackets" :key="index">
                    <v-card class="pa-2" tile>
                      <div class="text-h5 font-weight-bold text-secondary ">{{bracket.group}}</div>
                      <!-- <div class="text-body-2">Your Bets: <strong>5</strong> Slots</div> -->
                      <div class="text-body-2">Payout: <strong class="text-success">{{$money(getPayout(bracket.group))}} PHP</strong></div>
                    
                        <v-btn dark x-small rounded color="primary" @click="viewbracket=bracket.group, preview=true"> {{event.status=='Closed'?"View Score":'View'}}</v-btn> <v-btn v-if="event.status=='Open'" dark x-small color="tertiary" class="btn-grad-primary" @click="viewbracket=bracket.group, selectedbracket=bracket, validateBet()"> BET</v-btn>
                      
                    </v-card>
                  </v-col>
                 </v-row> 
                 </template>
                
               </v-col>
               <v-col cols="12">
               
                <div class="text-info text-body-2 mb-2"><v-icon left color="warning">mdi-poker-chip</v-icon><span class="text-info">Your bet(s) for this event. </span></div>
                <table class="va-table md-font ma-1  va-table-border-bot" cellspacing="0">
                    <tr>
                       
                        <td class="text-right" style="padding: 4px" colspan="4">TOTAL: {{$money(MYTOTAL)}}</td>
                      </tr>
                      <tr v-for="(item, index) in mybets" :key="index" @click="viewbet = item,viewbetDialog= true">
                        <td class="text-left" style="padding: 4px" :class="$IsEven(index)?'td-even':'odd-td'">{{item.pick}}</td>
                        <td class="text-left" style="padding: 4px" :class="$IsEven(index)?'td-even':'odd-td'">{{item.bracket}}</td>
                        <td class="text-left " :class="$IsEven(index)?'td-even':'odd-td'" style="padding: 4px" >
                          <span :class="item.status=='Win'?'text-success font-weight-bold':'text-info'">{{item.status}}</span>
                          <v-chip color="primary" small label v-if="event.status=='Completed'">
                                Score: {{item.score }}
                            </v-chip>
                        </td>
                        <td class="text-right" style="padding: 4px" :class="$IsEven(index)?'td-even':'odd-td'">{{$money(item.amount)}}</td>
                      </tr>
                 </table> 
                 
               </v-col>
            </v-row> 
            <va-all-entry  :show="entrypop" :entries="entries" @DialogEvent="entrypop=false"/>
          <va-view-bets :event_id="this.event.id" :bracket="viewbracket" :pick="gamename" :show="preview" @DialogEvent="preview=false,getMyBets()"/>
          <va-guide :data="event.description" :show="guide" @DialogEvent="guide=false"/>
          <va-bet-preview :event_id="this.event.id" :bracket="viewbracket" :pick="gamename" :bet="initBet()" :show="betpreview" @DialogEvent="evBet"/>
          <va-bet-view :event_id="this.event.id"  :bet="viewbet" :show="viewbetDialog" @DialogEvent="viewbetDialog= false"/>
          </v-container>
 </template>
 <script>
 import {
   mapMutations
 } from 'vuex'
   export default {
     data: () => ({
      entrypop: false,
       gamename: '',
       guide: false,
       viewbet: {},
       viewbetDialog: false,
       betpreview: false,
       selectedbracket: {},
       numpad: false,
       preview: false,
       ticket_dailog: false,
       selected:[],
       bracket:"",
       viewbracket:"",
       bets: [],
       mybets: [],
       event: {},
       entries:[],
       results:[],
       validated_lines:[],
       ticket:{},
       ticketpop: false,
       tickets:[]
     }),
     created() {
      if (this.user == null) {
          this.$router.push('/')
        }else {
          this.setLoggedIn(true)
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.getEvent()
        
        }
       
     },
      mounted(){
     
     },
     computed:{
        user() {
         if(localStorage.user){
             return JSON.parse(localStorage.user)
         }
         return null
       },
       BYTOPSCORES(){
        var items = this.entries
        return items.sort((a, b) =>   this.$IsNum(b.score) - this.$IsNum(a.score));
       },
       MYTOTAL(){
        var total = this.mybets.reduce((res, item)=>{
             res = res + this.$IsNum(item.amount)
             return res
           }, 0)
          return total
      },
       BYBRACKETS() {
        var allbets = this.$groupBy('bracket')
        return allbets(this.bets)
       },
      
       PICK(){
        if(this.gamename =="PICK 1") {
          return 1
        } else if(this.gamename =="PICK 2") {
          return 2
        } else if(this.gamename =="PICK 3") {
          return 3
        } else if(this.gamename =="PICK 4") {
          return 4
        } else if(this.gamename =="PICK 5") {
          return 5
        } else if(this.gamename =="PICK 6") {
          return 6
        }
        return 0
       }
     },
     methods: {
       ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', "setBettorName"]),
       getScore(entry){  
        var score = 0
        for(var i = 0; i < entry.fights.length;i++) {
          score = score + this.$IsNum(entry.fights[i])
        }
        return score.toString()
      },
       evBet(e){
        this.betpreview = false
        if(e=='bet') {
          console.log(this.PICK,this.selected.length )
          if(this.PICK == this.selected.length) {
            this.addBet()
          } else {
            this.VA_ALERT('error', "Lacking entries")
          }
          
        }
       },
       validateBet(){
          if(this.selected.length>0) {
            this.betpreview = true
          } else {
            this.VA_ALERT('error', "Select entries!")
          }
       },
       clearBet() {
        this.selected = []
       },
       getPayout(_group) {
        var bets = this.getStats(_group)
        var total = bets.reduce((res, item)=>{
          res =res + this.$IsNum(item.amount)  
          return res
        }, 0)
        return total * ((100 - this.$IsNum(this.event.percentage)))/100
       },
       getStats(_group) {
          for (let [key, value] of Object.entries(this.BYBRACKETS)) {
            if(_group == key) {
               var picks = this.$groupBy('pick')
               var _obj = picks(value)
              return _obj[this.gamename]?_obj[this.gamename]:[]
            } 
          }
          return []
       },
       selectEntry(item) {
        if(this.selected.length < this.PICK ) {
          this.selected.push(item)
          console.log(this.selected)
          this.selected = [...new Set(this.selected)]
          this.selected.sort((a, b) => a.entry_no - b.entry_no);
          console.log(this.selected)
        }
       },
       removeEntry(index) {
        this.selected.splice(index,1)
       },
       getMyBets(){
        this.$http.post("sidebet/qry", {event_id: this.$IsNum(this.$route.params.id), status: "mybets"} ).then(response => {
            response.data.items != null?this.mybets = response.data.items:this.mybets = []
            this.CHECK_BALANCE(this.user.id)
        }).catch(e => {
        console.log(e.data)
        })
       },
       getEvent: function() { 
        this.$http.post("event/get", {id: this.$IsNum(this.$route.params.id)} ).then(response => {
            response.data.item != null?this.event = response.data.item:this.event = {}
            this.getEntries()
            if(this.event.picks.length == 1) {
              this.gamename = this.event.picks[0]
            }
           
           // this.getMyBets()
        }).catch(e => {
        console.log(e.data)
        })
      },
      getEntries(){ 
        this.$http.post("entry/get", {event_id: this.$IsNum(this.$route.params.id)} ).then(response => {
            response.data.items != null?this.entries = response.data.items:this.entries = []
            this.getBets('all')
        }).catch(e => {
        console.log(e.data)
        })
     },
     getBets(status){  
        this.$http.post("sidebet/qry", {event_id: this.$IsNum(this.$route.params.id), status: status} ).then(response => {
            response.data.items != null?this.bets = response.data.items:this.bets = []
            this.CHECK_BALANCE(this.user.id)
            this.getMyBets()
        }).catch(e => {
        console.log(e.data)
        })
     },
     parseEntries(){
        var ids = []
        var names = []
        for(var i = 0; i < this.selected.length;i++) {
          ids.push(this.selected[i].entry_no)
          names.push(this.selected[i].name)
        }
        return {ids:ids.toString(), names: names.toString()}
     },
     initBet(){
        let entry = this.parseEntries()
        let bet = {}
        bet.event_id = this.event.id
        bet.pick = this.gamename
        bet.amount = this.selectedbracket.bet
        bet.entries = entry.ids
        bet.entry_names = entry.names
        bet.bracket = this.selectedbracket.group
        return bet
     },
     addBet(){ 
        let bet = this.initBet()
        this.$http.post("sidebet/new", bet).then(response => {
            response.data.status?this.VA_ALERT('success', "Bet has been posted!"):this.VA_ALERT('error', response.data.message)
            if(response.data.status) {
              setTimeout(()=>{
                this.getBets('all')
                this.clearBet()
              }, 1500)
            }
        }).catch(e => {
        console.log(e.data)
        })
     },
     },
   }
 </script>